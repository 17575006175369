import 'bootstrap';
import Swiper from 'swiper/bundle';
import 'magnific-popup';
import '../sass/main.scss';
import AOS from 'aos';

// document.ready
$(document).ready(function () {
    const currentUrlPath = window.location.pathname;
    const filename = currentUrlPath.split('/').pop();
    $('header .nav-tab a').each(function () {
        const href = $(this).attr('href');
        const tabFilename = href.split('/').pop();
        if (filename === tabFilename) {
            $('.nav-tab').removeClass('active');
            $(this).closest('.nav-tab').addClass('active');
        }
    });

    // aos animation
    setTimeout(() => {
        AOS.init();
    }, 2000);

    $(window).on('resize', function () {
        AOS.init();
    });

    const header = $('header');
    const transparentHeader = $('.header-transparent');
    const body = $('body');
    const headerHeight = $('header').outerHeight();
    let lastScrollTop = 0;

    function updateBodyPadding() {
        body.css('padding-top', headerHeight + 'px');

        if (transparentHeader.length > 0) {
            body.css('padding-top', '0px');
        }
    }
    updateBodyPadding();

    function checkScrollDirection() {
        const st = $(window).scrollTop();
        const scrollDifference = Math.abs(st - lastScrollTop);

        if (scrollDifference >= 200) {
            if (st > lastScrollTop) {
                // Scrolling down - hide header
                header.addClass('header-hidden').removeClass('header-top');
            } else {
                // Scrolling up - show header
                header.addClass('header-top').removeClass('header-hidden');
                header.removeClass('white-header');
            }
            lastScrollTop = st;
        }

        if (st >= 40) {
            header.addClass('white-header');
            header.removeClass('header-transparent');
        } else {
            header.removeClass('white-header');
            if (header.hasClass('transparent')) {
                header.addClass('header-transparent');
            }
        }

        if (st === 0) {
            header.removeClass('header-top');
        }
    }

    // Initial check on page load
    checkScrollDirection();

    // Listen for scroll events
    $(window).on('scroll', function () {
        checkScrollDirection();
    });

    $('#menuToggle').on('change', function () {
        if ($(this).prop('checked')) {
            $('body').addClass('menu-open');
        } else {
            $('body').removeClass('menu-open');
        }
    });

    // generic-swiper1
    $('.generic-swiper').each(function () {
        const genericSwiper = $(this);

        if (genericSwiper.length > 0) {
            const cardsForMobile = genericSwiper.data('slides-mobile') || 1;
            const cardsForTablet = genericSwiper.data('slides-tablet') || 2.2;
            const cardsForDesktop = genericSwiper.data('slides-desktop') || 2.5;
            const cardsForLargeDesktop = genericSwiper.data('slides-large-desktop') || 2.5;
            const slidesSpaceDesktop = genericSwiper.data('slides-space-desktop') || 60;
            
            const enableLoop = genericSwiper.find('.swiper-slide').length > cardsForDesktop;

            new Swiper(this, {
                slidesPerView: cardsForMobile,
                spaceBetween: 25,
                observer: true,
                observeParents: true,
                loop: enableLoop,
                resizeObserver: true,
                updateOnWindowResize: true,
                loopAdditionalSlides: 30,

                autoplay: enableLoop ? {
                    delay: 3000,
                    disableOnInteraction: false,
                } : false ,

                navigation: {
                    prevEl: $(this).parents('.navigation-y-center').find('.swiper-button-prev-g1')[0],
                    nextEl: $(this).parents('.navigation-y-center').find('.swiper-button-next-g1')[0],
                },

                pagination: {
                    el: '.swiper-pagination',
                    clickable: true,
                },

                breakpoints: {
                    768: {
                        slidesPerView: cardsForTablet,
                    },
                    992: {
                        slidesPerView: cardsForTablet,
                        spaceBetween: 40,
                    },
                    1366: {
                        slidesPerView: cardsForDesktop,
                        spaceBetween: slidesSpaceDesktop,
                    },
                    1600: {
                        slidesPerView: cardsForLargeDesktop,
                        spaceBetween: slidesSpaceDesktop,
                    },
                },
            });
        }
    });

    // generic-swiper2
    genericSwiper2();
    function genericSwiper2() {
        const genericSwiper2 = $('.generic-swiper2');

        if (genericSwiper2.length > 0) {
            genericSwiper2.each(function () {
                new Swiper(this, {
                    slidesPerView: 1,
                    spaceBetween: 25,
                    observer: true,
                    observeParents: true,
                    loop: true,
                    resizeObserver: true,
                    updateOnWindowResize: true,

                    autoplay: {
                        delay: 3000,
                        disableOnInteraction: false,
                    },
                    navigation: {
                        prevEl: $(this).parents('.navigation-y-center').find('.swiper-button-prev-g2')[0],
                        nextEl: $(this).parents('.navigation-y-center').find('.swiper-button-next-g2')[0],
                    },
                    pagination: {
                        el: '.swiper-pagination',
                        clickable: true,
                    },
                    breakpoints: {
                        640: {
                            slidesPerView: 1.5,
                        },
                        768: {
                            slidesPerView: 2,
                        },
                        992: {
                            slidesPerView: 2.7,
                            spaceBetween: 40,
                        },
                        1366: {
                            slidesPerView: 3,
                        },
                    },
                });
            });
        }
    }

    // our-procurement
    const galleryContent = new Swiper('.our-procurement-content', {
        slidesPerView: 1,
        allowTouchMove: false,
        observer: true,
        observeParents: true,
        loop: true,
        resizeObserver: true,
        updateOnWindowResize: true,
        loopAdditionalSlides: 30,
        
        autoplay: {
            delay: 3000,
            disableOnInteraction: false,
        },
    });
    const galleryThumbs = new Swiper('.our-procurement-thumb', {
        spaceBetween: 45,
        slidesPerView: 1,
        observer: true,
        observeParents: true,
        loop: true,
        resizeObserver: true,
        updateOnWindowResize: true,
        loopAdditionalSlides: 30,

        autoplay: {
            delay: 3000,
            disableOnInteraction: false,
        },

        navigation: {
            nextEl: '.our-procurement-content-next',
            prevEl: '.our-procurement-content-prev',
        },

        pagination: {
            el: '.our-procurement-content-pagination',
            clickable: true,
        },

        breakpoints: {
            768: {
                slidesPerView: 1.3,
            },
            992: {
                slidesPerView: 2,
            },
        },

        thumbs: {
            swiper: galleryContent,
        },
    });

    new Swiper('.logo-swiper', {
        slidesPerView: 2,
        spaceBetween: 20,
        observer: true,
        observeParents: true,
        loop: true,

        autoplay: {
            delay: 3000,
            disableOnInteraction: false,
        },
        breakpoints: {
            768: {
                slidesPerView: 3,
            },
            992: {
                slidesPerView: 4,
                spaceBetween: 20,
            },
            1366: {
                slidesPerView: 5,
                spaceBetween: 40,
            },
        },
    });

    new Swiper('.story-swiper', {
        slidesPerView: 1,
        spaceBetween: 60,
        observer: true,
        observeParents: true,

        autoplay: {
            delay: 3000,
            disableOnInteraction: false,
        },
        pagination: {
            el: '.swiper-pagination',
            clickable: true,
        },
        breakpoints: {
            768: {
                slidesPerView: 2,
            },
            992: {
                slidesPerView: 3,
                spaceBetween: 60,
            },
            1366: {
                slidesPerView: 3,
            },
        },
    });

    $('.popup-video').magnificPopup({
        items: {
            src: 'https://www.youtube.com/watch?v=EngW7tLk6R8',
        },
        type: 'iframe',
    });

    // horizontal tabs
    $('.horizontal-tabs-container .nav-link').on('click', function () {
        const activeTab = $(this);
        const container = $('.horizontal-tabs-container .nav-tabs');
        const containerWidth = container.width();
        const activeTabWidth = activeTab.outerWidth();
        const activeTabLeft = activeTab.position().left;
        const containerScrollLeft = container.scrollLeft();

        // Calculate the new scroll position to center the active tab
        const scrollPosition =
            activeTabLeft + containerScrollLeft - containerWidth / 2 + activeTabWidth / 2;

        container.animate(
            {
                scrollLeft: scrollPosition,
            },
            500,
        );
    });

    
    function setSwiperNavigationPosition() {
        $('.navigation-y-image-center').each(function () {
            let heights = $(this).find('.image-wrapper')
                .map(function () {
                    return $(this).height();
                })
                .get();
            let maxHeight = Math.max.apply(null, heights);
            $(this).find('.swiper-navigation').css('top', maxHeight / 2 + 'px');
        });
    }

    setSwiperNavigationPosition();

    $(window).on('load resize', setSwiperNavigationPosition);

    const footerModalController = (id, btn)=>{
        const modal = document.querySelector(id);
        const triggerBtn = document.querySelector(btn);
        const closeButton = modal?.querySelector(".close-button");

        triggerBtn?.addEventListener("click", ()=>{
            toggleModal(modal);
        })

        function toggleModal(modalEl) {
            modalEl.classList.toggle("show-modal");
        }

        function windowOnClick(event) {
            if (event.target === modal) {
              toggleModal(modal);
            }
        }

        closeButton?.addEventListener("click", ()=> toggleModal(modal));
        window.addEventListener("click", windowOnClick);

    }

    footerModalController('#disclaimer',".disclaimer-btn");
    footerModalController('#terms-of-use',".terms-of-use-btn");
    footerModalController('#privacy-policy',".privacy-policy-btn");

    // Fancybox plugin 
    // add all to same gallery
    $(".gallery a").attr("data-fancybox","mygallery");
    // assign captions and title from alt-attributes of images:
    $(".gallery a").each(function(){
        $(this).attr("data-caption", $(this).find("img").attr("alt"));
        $(this).attr("title", $(this).find("img").attr("alt"));
    });
    // start fancybox:
    $(".gallery a").fancybox({
        loop: false,
        protect: true
    });

});
